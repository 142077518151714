<template>
  <v-dialog
    v-if="help != true"
    :width="this.size != null ? size : '400'"
    v-model="dialog"
  >
    <template v-slot:activator="{ on }">
      <v-btn elevation="0" icon v-on="on" @click="open" style="margin-top: 5px">
        <v-icon v-if="small" small>mdi-plus-circle</v-icon>
        <v-icon v-else size="40">mdi-plus-circle</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ $t("routes.categoryCreate") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false">
          <v-icon id="iconClose">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text class="pb-2" style="padding-top: 0 !important">
        <categorys-form
          :title="$t('new', { name: $tc('category') })"
          :category="category"
          @validated="saveCategory()"
          @back="dialog = false"
        ></categorys-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
//import constants from "@/constants";
export default {
  name: "CategoryNew",
  props: ["small"],
  components: { CategorysForm: () => import("@/components/category/Form") },
  data() {
    return {
      category: {},
      dialog: false,
    };
  },
  methods: {
    ...mapActions("category", ["addCategory"]),
    saveCategory() {
      this.addCategory({ category: this.category }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("category_create_success"), "", "");
          this.dialog = false;
          this.$emit("save");
        }
      });
    },
    open() {
      this.category = {};
    },
  },
};
</script>
